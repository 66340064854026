import { AWS } from "../../../../src/components/LifelongLearning";
import { CCDAK, CCOAK } from "../../../../src/components/Tech";
import { Kafka } from "../../../../src/components/Tech";
import * as React from 'react';
export default {
  AWS,
  CCDAK,
  CCOAK,
  Kafka,
  React
};