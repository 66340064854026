import TubeHero from "../../../../src/components/tube-hero";
import KafkaCourses from "../../../../src/components/credits/kafka/kafka-courses";
import KafkaAccreditation from "../../../../src/components/credits/kafka/kafka-accreditation";
import { Link as GatsbyLink } from "gatsby";
import * as React from 'react';
export default {
  TubeHero,
  KafkaCourses,
  KafkaAccreditation,
  GatsbyLink,
  React
};