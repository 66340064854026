import { VisualStudio, OpenSource, Microsoft, VB } from "../../../../src/components/Tech";
import { SimpleSteph } from "../../../../src/components/LifelongLearning";
import * as React from 'react';
export default {
  VisualStudio,
  OpenSource,
  Microsoft,
  VB,
  SimpleSteph,
  React
};