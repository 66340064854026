import { Link as GatsbyLink } from "gatsby";
import { CPD } from "../../../../src/components/LifelongLearning";
import { KSQL } from "../../../../src/components/LifelongLearning";
import { Kafka } from "../../../../src/components/LifelongLearning";
import * as React from 'react';
export default {
  GatsbyLink,
  CPD,
  KSQL,
  Kafka,
  React
};