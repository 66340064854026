import { Maths, Warwick, PlateGlassUni, RussellGroup } from "../../../../src/components/Education";
import { Scala } from "../../../../src/components/LifelongLearning";
import * as React from 'react';
export default {
  Maths,
  Warwick,
  PlateGlassUni,
  RussellGroup,
  Scala,
  React
};