import { Scala } from "../../../../src/components/LifelongLearning";
import { VisualStudio, OpenSource, Microsoft, VB } from "../../../../src/components/Tech";
import * as React from 'react';
export default {
  Scala,
  VisualStudio,
  OpenSource,
  Microsoft,
  VB,
  React
};