import { Android, Kotlin, Java, Ionic, ReactLink, Google, SpringBoot, Akka, Kafka } from "../../../../src/components/Tech";
import * as React from 'react';
export default {
  Android,
  Kotlin,
  Java,
  Ionic,
  ReactLink,
  Google,
  SpringBoot,
  Akka,
  Kafka,
  React
};