import { AWS } from "../../../../src/components/LifelongLearning";
import { AmazonCorretto } from "../../../../src/components/Tech";
import { Amazon } from "../../../../src/components/Tech";
import { Oracle } from "../../../../src/components/Tech";
import * as React from 'react';
export default {
  AWS,
  AmazonCorretto,
  Amazon,
  Oracle,
  React
};