import TubeHero from "../../../../src/components/tube-hero";
import AwsCourses from "../../../../src/components/credits/aws/aws-courses";
import AwsAccreditation from "../../../../src/components/credits/aws/aws-accreditation";
import { Link as GatsbyLink } from "gatsby";
import * as React from 'react';
export default {
  TubeHero,
  AwsCourses,
  AwsAccreditation,
  GatsbyLink,
  React
};